import {
  Component,
  createMemo,
  Show,
  createEffect,
  createSignal,
} from "solid-js";
import { SelectBox } from "~/components/inputs";
import { useItemOrderContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { informationCircle } from "solid-heroicons/outline";
import { InfoBox } from "~/components/utility";
import {
  minWidthForDoubleWedge,
  minWidthForSingleWedge,
} from "~/utils/builtFrames";
import { PT } from "~/utils/products";

import {
  WEDGE,
  type WedgeOption,
  WedgeLabels,
} from "~/services/roma-api/cart/types-productOrdering";

// const wedgeLockOptions = [
//   { value: "none", label: "Not Required" },
//   { value: "single", label: "Single Wedge" },
//   { value: "double", label: "Double Wedge" },
// ];

export const wedgeLockOptions = Object.values(WEDGE).map((value) => ({
  value: value as WedgeOption,
  label: WedgeLabels[value],
}));

const stackWedgeOptions = [
  { value: WEDGE.NONE, label: WedgeLabels[WEDGE.NONE] },
  { value: "TRUE", label: "Add Wedge Locks" },
];

export const WedgeLock: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();
  const [showWedgeInfo, setShowWedgeInfo] = createSignal(false);

  // THINGS TO CHECK - stack/nonstack, edit/new order, floater, moulding width:

  const noWedgeAllowed = createMemo(() => {
    if (!orderData.width) return false;

    if (!orderData.selected.productType?.includes(PT.STACK)) {
      if (
        orderData.currentProduct?.Floater == true ||
        orderData.width < minWidthForSingleWedge
      ) {
        return true;
      }
      return false;
    }

    if (orderData.selected.productType.includes(PT.STACK)) {
      // Typescript checking..
      if (
        !(
          orderData.selected?.stacks?.outsideDetails?.mouldingWidth &&
          orderData.selected?.stacks?.insideDetails?.mouldingWidth
        )
      )
        return false;

      if (
        ["stackJoin3", "stackChop3", "stackMirror3"].includes(
          orderData.selected.productType
        )
      ) {
        // typescript checking..
        if (!orderData.selected?.stacks?.middleDetails?.mouldingWidth)
          return false;

        if (
          orderData.selected.stacks.outsideDetails.mouldingWidth <=
            minWidthForSingleWedge &&
          orderData.selected.stacks.middleDetails.mouldingWidth <=
            minWidthForSingleWedge &&
          orderData.selected.stacks.insideDetails.mouldingWidth <=
            minWidthForSingleWedge
        ) {
          return true;
        }
        return false;
      }

      if (
        ["stackJoin2", "stackChop2", "stackMirror2"].includes(
          orderData.selected.productType
        )
      ) {
        if (
          orderData.selected.stacks.outsideDetails.mouldingWidth <=
            minWidthForSingleWedge &&
          orderData.selected.stacks.insideDetails.mouldingWidth <=
            minWidthForSingleWedge
        ) {
          return true;
        }
        return false;
      }
    }
  });

  createEffect(() => {
    if (!noWedgeAllowed()) return;
    if (noWedgeAllowed()) {
      setOrderData("selected", "wedgeLock", "none");
    }
  });

  const options = createMemo(() => {
    if (!orderData.width) return wedgeLockOptions;

    if (orderData.selected.productType?.includes(PT.STACK)) {
      return stackWedgeOptions;
    }

    if (orderData.width < minWidthForDoubleWedge) {
      return [
        ...wedgeLockOptions.filter(
          (item) => !WEDGE.DOUBLE.includes(item.value)
        ),
      ];
    } else if (orderData.width >= minWidthForDoubleWedge) {
      return [
        ...wedgeLockOptions.filter(
          (item) => !WEDGE.SINGLE.includes(item.value)
        ),
      ];
    }
    return wedgeLockOptions;
  });

  return (
    <>
      <div class="w-full flex flex-col gap-2">
        <div>
          <p class="text-sm font-bold text-roma-dark-grey inline-block">
            Wedge Locks
          </p>
          <Show when={noWedgeAllowed()}>
            <button
              aria-label="Show additional information"
              onClick={() => {
                setShowWedgeInfo(true);
              }}
            >
              <Icon path={informationCircle} class="w-5 mx-2 inline-block" />
            </button>
          </Show>
        </div>
        <SelectBox
          options={options()}
          onChange={(option) =>
            setOrderData(
              "selected",
              "wedgeLock",
              option.value as WedgeOption | "TRUE"
            )
          }
          defaultValue={{ value: orderData.selected.wedgeLock }}
          placeholder="Select a wedge lock option"
          triggerClass="rounded-sm"
          disabled={noWedgeAllowed()}
        />

        <Show when={showWedgeInfo()}>
          <div>
            <InfoBox close={() => setShowWedgeInfo(false)}>
              Wedge locks are only available on mouldings wider than{" "}
              <span class="fraction">3/4"</span>
            </InfoBox>
          </div>
        </Show>
      </div>
      {/* <Show when={orderData.selected.productType.includes(PT.STACK) && orderData.selected.wedgeLock === "TRUE"}>
        <InfoBox>
          Wedge locks will be added to qualifying mouldings in the stack based on moulding width.
          <div class="grid grid-cols-2 w-[150px] mt-2 fraction italic border divide-y child:pl-2 child:py-1 divide-x">
            <p> &#60; 3/4"</p>
            <p>None</p>
            <p> 3/4" - 2" </p>
            <p>Single</p>
            <p>&#62; 2"</p>
            <p>Double</p>
          </div>
        </InfoBox>
      </Show> */}
    </>
  );
};
