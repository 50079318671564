import {
  Component,
  For,
  Show,
  onMount,
} from "solid-js";
import { debounce } from "@solid-primitives/scheduled";
import { createStore } from "solid-js/store";
import { Input } from "~/components/inputs";
import { Motion, Presence } from "solid-motionone";
import { sixteenthsList, splitNum } from "~/utils/fraction";
import fractionString from "~/utils/fraction";

export const ComboSizeSelector: Component<{
  label?: string;
  name: string;
  update: (size: number) => void;
  placeholder?: string;
  class?: string;
  initialVal?: number;
  tabIndex?: string;
  error?: string;
}> = (props) => {
  const [values, setValues] = createStore({
    integer: 0,
    fraction: 0,
    expanded: false,
    get combinedVal() {
      return +this.integer + +this.fraction;
    },
  });
  onMount(() => {
    if (!props.initialVal) return;
    const [int, frac] = splitNum(props.initialVal);
    setValues({
      integer: int,
      fraction: frac,
    });
  });
  const updateAmount = debounce(() => props.update(values.combinedVal), 500);
  return (
    <div class="flex flex-col">
      <Show when={props.label}>
        <div class="text-roma-dark-grey mb-1">{props.label}</div>
      </Show>
      <div
        class="flex text-center relative items-end text-sm leading-6 border border-roma-gray text-roma-dark-grey"
        classList={{
          [props.class!]: !!props.class,
          "border-red-300": !!props.error,
        }}
      >
        <Show
          when={values.expanded}
          fallback={
            <button
              onFocus={() => setValues("expanded", true)}
              onClick={() => setValues("expanded", true)}
              onTouchStart={() => setValues("expanded", true)}
              class="px-3 py-2 leading-5 flex justify-center cursor-text w-full"
            >
              <Show
                fallback={
                  <span class="text-gray-400">{props.placeholder}</span>
                }
                when={values.combinedVal != 0}
              >
                <Show when={values.integer > 0}>{values.integer}</Show>
                <Show fallback='"' when={values.fraction !== 0}>
                  <div
                    class="fraction"
                    classList={{
                      "pl-1": values.integer > 0,
                    }}
                  >
                    {fractionString(values.fraction)}
                  </div>
                </Show>
              </Show>
            </button>
          }
        >
          <Input
            id={props.name}
            name={props.name}
            type="number"
            min={5}
            tabIndex={props.tabIndex}
            focusOnLoad={true}
            pattern="^[\d]*$"
            class="h-11 border-0 text-center rounded-none"
            outerClass="w-full"
            placeholder={props.placeholder}
            onLoad={(ref) => {
              if (values.combinedVal > 0) {
                ref.value = values.combinedVal;
              }
            }}
            onFocus={() => setValues("expanded", true)}
            onBlur={() => {
              setValues("expanded", false);
              updateAmount();
            }}
            onKeyPress={(e) => {
              if (e.isComposing || e.keyCode === 13) {
                setValues("expanded", false);
              }
            }}
            onInput={(e) => {
              const num = parseFloat(e.currentTarget.value);
              if (isNaN(num)) {
                setValues("integer", 0);
                return;
              }
              const [integer, fraction] = splitNum(num);
              let selected = 0;
              for (const i in sixteenthsList) {
                if (fraction == sixteenthsList[i]) {
                  selected = sixteenthsList[i];
                }
              }
              setValues({ integer, fraction: selected });
              updateAmount();
            }}
          />
        </Show>
        <div>
          <Presence exitBeforeEnter>
            <Show when={values.expanded}>
              <Motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10, transition: { duration: 0.5 } }}
                transition={{ duration: 0.1, easing: "ease-in-out" }}
                class="absolute w-full right-0 z-20 overflow-auto mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                role="menu"
                tabindex="-1"
              >
                <div class="py-1 flex flex-col" role="none">
                  <For each={sixteenthsList.filter((size) => size !== 0)}>
                    {(size) => (
                      <button
                        classList={{
                          "bg-roma-blue text-white": values.fraction == size,
                          "hover:bg-gray-100": values.fraction != size,
                        }}
                        class="w-full fraction border-b border-gray-100 p-3 text-lg"
                        onTouchStart={() =>
                          setValues({
                            // fraction: size,
                            expanded: true,
                          })
                        }
                        onClick={() => {
                          setValues({
                            fraction: size,
                            expanded: false,
                          });
                        }}
                      >
                        <Show when={values.integer > 0}>{values.integer}</Show>{" "}
                        {fractionString(size)}
                      </button>
                    )}
                  </For>
                </div>
              </Motion.div>
            </Show>
          </Presence>
        </div>
      </div>
    </div>
  );
};
