import {
  Component,
  Show,
  createSignal,
  For,
  createResource,
  Switch,
  Match,
  createEffect,
  onMount,
  batch,
} from "solid-js";
import { produce } from "solid-js/store";
import { A } from "@solidjs/router";
import { useSiteContext, useItemOrderContext } from "~/utils/contexts";
import { debounce } from "@solid-primitives/scheduled";
import { ProductThumbnail, ProductPlaceholder } from ".";
import { Icon } from "solid-heroicons";
import { magnifyingGlass, xMark, arrowsUpDown } from "solid-heroicons/outline";
// TODO
//import { OutsideClickHandler } from "solid-outside-click-handler"; // ? Necessary ? find another way..
import { EventType } from "@solid-primitives/analytics";
import algoliasearch from "algoliasearch";
import type { AlgoliaMouldingResult } from "~/utils/types";

type SelectedProduct = {
  SKU: string;
  Width: number;
  Height: number;
  Rabbet: number;
  Collection: string;
  Category: string;
  ColourDescription: string;
  AvailableAs: string[];
  Discontinued: boolean;
  Floater: boolean;
  ProductType: string;
};

export const StackProductSearch: Component<{
  label?: string;
  position?: "inside" | "outside" | "middle";
  storePath?: string[];
  preloadSku?: string;
  onClick?: (sku: string) => void;
  hidePlaceholder?: boolean;
  searchClass?: string;
  showSwap?: boolean;
  swapFunction?: VoidFunction;
}> = (props) => {
  let searchInputRef: HTMLInputElement;
  const { track } = useSiteContext();
  const { setOrderData } = useItemOrderContext();
  const [searchValue, setSearchValue] = createSignal("");
  const [showSearchResults, setShowSearchResults] = createSignal(false);
  const [isFillet, setIsFillet] = createSignal(false);
  const [selectedProduct, setSelectedProduct] = createSignal<
    SelectedProduct | undefined
  >(undefined);
  const search = debounce((value: string) => setSearchValue(value), 500);
  const client = algoliasearch(
    import.meta.env.VITE_ALGOLIA_APP_ID,
    import.meta.env.VITE_ALGOLIA_API_KEY
  );
  const index = client.initIndex(import.meta.env.VITE_ALGOLIA_INDEX);
  const [products] = createResource(
    () => searchValue(),
    async (searchValue) => {
      track(EventType.Event, {
        category: "search",
        action: "selected",
        value: searchValue,
      });
      const response = await index.search<AlgoliaMouldingResult>(searchValue, {
        hitsPerPage: 100,
        filters: "active:true",
        attributesToRetrieve: [
          "sku",
          "name",
          "width",
          "height",
          "rabbet",
          "category",
          "collection",
          "color_description",
          "available_as",
          "discontinued",
          "is_floater",
          "product_type",
        ],
      });
      track(EventType.Event, {
        category: "search",
        action: "selected_results",
        value: response.hits.length.toString(),
      });
      return {
        Total: response.hits.length,
        Results: response.hits.map((hit) => ({
          SKU: hit.objectID,
          Width: hit.width,
          Height: hit.height,
          Rabbet: hit.rabbet,
          Collection: hit.collection,
          Category: hit.category,
          ColourDescription: hit.color_description,
          AvailableAs: hit.available_as,
          Discontinued: hit.discontinued,
          Floater: hit.is_floater,
          ProductType: hit.product_type,
        })),
      };
    },
    {
      initialValue: {
        Total: 0,
        Results: [],
      },
      ssrLoadFrom: "initial",
    }
  );
  onMount(() => {
    setTimeout(() => searchInputRef.focus(), 150);
  });

  createEffect(() => {
    if (props.preloadSku) {
      setSearchValue(props.preloadSku);
      const choice = { ...products.latest.Results[0] };
      batch(() => {
        setSelectedProduct(choice);
        updateStore({
          mouldingWidth: choice.Width,
          mouldingHeight: choice.Height,
          floater: choice.Floater ? true : undefined,
          fillet: choice.ProductType === "Fillet" ? true : undefined,
          category: choice.Category,
          discontinued: choice.Discontinued,
        });
        setIsFillet(choice.ProductType == "Fillet");
      });
    }
  });

  // helper function to use produce() instead of multiple calls to setOrderData()
  // works with previous implentation of the storePath being provided as string[] via props.
  const updateStore = (updates: Record<any, any>) => {
    setOrderData(
      produce((state) => {
        let target = props.storePath!.reduce(
          (obj: any, key) => obj[key],
          state
        );
        for (let key in updates) {
          (target as Record<string, any>)[key] = updates[key];
        }
      })
    );
  };

  const clearProductSelection = () => {
    batch(() => {
      setSelectedProduct(undefined);
      setSearchValue("");
      setIsFillet(false);
      updateStore({
        sku: undefined,
        width: undefined,
        height: undefined,
        floater: undefined,
        fillet: undefined,
        mouldingWidth: undefined,
        mouldingHeight: undefined,
        stockLevel: undefined,
        discontinued: undefined,
        category: undefined,
      });
    });
  };

  return (
    <div class="relative">
      <div
        class="rounded-sm bg-white border rounded-t-sm px-3 py-2 shadow-sm relative focus-within:ring-1 ring-roma-blue focus-within:z-10"
        classList={{ [`${props.searchClass}`]: !!props.searchClass }}
      >
        <label for="name" class="block text-xs font-medium text-roma-dark-grey">
          {props.label}
        </label>
        <input
          id="name"
          name="name"
          ref={(ref) => (searchInputRef = ref)}
          autocomplete="off"
          type="text"
          class="block w-full border-0 p-0 transition duration-500 placeholder:pl-2 placeholder-gray-500 sm:text-sm outline-none disabled:bg-white"
          placeholder="Enter moulding number"
          onInput={(e) => {
            search(e.currentTarget.value);
            setShowSearchResults(true);
          }}
          value={searchValue()}
          disabled={!!selectedProduct()}
          classList={{
            "text-gray-900": !selectedProduct(),
            "text-roma-medium-grey": !!selectedProduct(),
          }}
        />
        <div class="absolute inset-y-0 right-0 mr-3 h-full flex items-center divide-x">
          <Show when={props.showSwap && !!props.swapFunction}>
            <button
              class="inline-block mr-1 hover:text-gray-500"
              onClick={props.swapFunction}
            >
              <Icon path={arrowsUpDown} class="w-6 h-6" />
            </button>
          </Show>
          <Show
            when={selectedProduct()}
            fallback={
              <span
                class={`inline-block ${
                  selectedProduct() ? "px-2 text-gray-300" : "text-gray-900"
                }`}
              >
                <Icon path={magnifyingGlass} class="w-6 h-6" />
              </span>
            }
          >
            <span class=" group cursor-pointer" onClick={clearProductSelection}>
              <Icon
                path={xMark}
                class="w-7 h-7 text-gray-900 group-hover:text-gray-500"
              />
            </span>
          </Show>
        </div>
      </div>
      <div class=" border-t-0 rounded-b-sm relative">
        <Switch>
          <Match when={!showSearchResults()}>
            <Show
              when={selectedProduct()}
              fallback={!props.hidePlaceholder ? <ProductPlaceholder /> : null}
            >
              <ProductThumbnail
                collectionName={selectedProduct()!.Collection}
                width={selectedProduct()!.Width}
                colour={selectedProduct()!.ColourDescription}
                sku={selectedProduct()!.SKU}
                disableHover={true}
                containerClass="border"
              />
            </Show>
          </Match>
          <Match when={showSearchResults()}>
            {/*<OutsideClickHandler
              onOutsideClick={() => setShowSearchResults(false)}
            >*/}
            <div class="border rounded-sm absolute inset-x-0 max-h-96 overflow-y-auto bg-white z-30 ">
              <div class="divide-y">
                <For
                  each={products.latest.Results}
                  fallback={
                    <div class="flex justify-center items-center">
                      <p class="text-roma-medium-grey py-10 text-center p-5">
                        No products found, enter another moulding number or{" "}
                        <A href="/shop" class="text-roma-blue">
                          browse all of our products
                        </A>{" "}
                      </p>
                    </div>
                  }
                >
                  {(item, index) => (
                    <ProductThumbnail
                      collectionName={item.Collection}
                      width={item.Width}
                      colour={item.ColourDescription}
                      sku={item.SKU}
                      onClick={() => {
                        const trigger = props.onClick
                          ? () => props.onClick!(item.SKU)
                          : () => {
                              const choice = {
                                ...products.latest.Results[index()],
                              };
                              batch(() => {
                                setSelectedProduct(choice);
                                if (searchInputRef)
                                  searchInputRef.value = choice.SKU;
                                setShowSearchResults(false);
                                setIsFillet(choice.ProductType == "Fillet");
                                updateStore({
                                  sku: choice.SKU,
                                  mouldingWidth: choice.Width,
                                  mouldingHeight: choice.Height,
                                  floater: choice.Floater ? "true" : undefined,
                                  fillet:
                                    choice.ProductType === "Fillet"
                                      ? true
                                      : undefined,
                                  category: choice.Category,
                                  discontinued: choice.Discontinued,
                                });
                              });
                            };
                        trigger();
                        track(EventType.Event, {
                          category: "search",
                          action: "selected",
                          value: item.SKU,
                        });
                      }}
                    />
                  )}
                </For>
              </div>
            </div>
            {/*</OutsideClickHandler> */}
          </Match>
        </Switch>
      </div>
      <Show
        when={
          selectedProduct() &&
          selectedProduct()?.Floater == true &&
          props.position !== "inside"
        }
      >
        <div class="bg-roma-grey bg-opacity-95 border absolute inset-0 z-[41] flex justify-center items-center">
          <button
            aria-label="Clear product selection"
            class="absolute top-0 right-0"
            onClick={clearProductSelection}
          >
            <Icon path={xMark} class="w-7 m-3" />
          </button>
          <p class="p-10">
            Floater mouldings must be the innermost layer of a stack, please
            select another moulding for this layer.
          </p>
        </div>
      </Show>
      <Show when={props.position !== "inside" && isFillet()}>
        <div class="bg-roma-grey bg-opacity-95 border absolute inset-0 z-[41] flex justify-center items-center">
          <button
            aria-label="Clear product selection"
            class="absolute top-0 right-0"
            onClick={clearProductSelection}
          >
            <Icon path={xMark} class="w-7 m-3" />
          </button>
          <p class="p-10">
            Fillet mouldings must be the innermost layer of a stack, please
            select another moulding for this layer.
          </p>
        </div>
      </Show>
    </div>
  );
};
