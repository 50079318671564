import { Component, onMount, onCleanup } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { check } from "solid-heroicons/outline";

export const BackOrderAcknowledge: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();
  onMount(() => {
    setOrderData("selected", "backorderConsent", undefined);
  });
  onCleanup(() => {
    setOrderData("selected", "backorderConsent", undefined);
  });
  return (
    <div class="border bg-faint-blue p-3 flex items-center text-sm text-roma-dark-grey">
      <button
        aria-label={
          !orderData.selected.backorderConsent
            ? "Check backorder consent box"
            : "Uncheck backorder consent box"
        }
        class="ml-2 w-5 aspect-square shrink-0 flex justify-center items-center self-center"
        classList={{
          "bg-white border": !orderData.selected.backorderConsent,
          "bg-roma-blue": !!orderData.selected.backorderConsent,
        }}
        onClick={() => {
          setOrderData(
            "selected",
            "backorderConsent",
            !orderData.selected.backorderConsent
          );
        }}
      >
        <Icon path={check} class="text-white w-4" stroke-width={3} />
      </button>
      <p class="ml-4">
        In order to proceed with checkout, please acknowledge that this item
        will remain on backorder until stock is replenished.
      </p>
    </div>
  );
};
