import { Show, Component } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { plus, minus } from "solid-heroicons/outline";
import { TextFieldInput } from "~/components/inputs";
import { debounce } from "@solid-primitives/scheduled";

export const QtyCounter: Component<{ disabled?: boolean }> = (props) => {
  const { orderData, setOrderData } = useItemOrderContext();

  const setQty = debounce((val: string | number) => {
    setOrderData("selected", "quantity", Number(val));
  }, 300);

  return (
    <Show when={orderData}>
      <div class="flex flex-col gap-1 items-center mr-auto">
        <p class="text-sm font-bold text-roma-dark-grey self-start">Quantity</p>
        <div class="flex justify-around w-48">
          <button
            aria-label="decrement quantity by 1"
            class="p-2 border border-r-0 border-gray-300"
            classList={{
              hidden: !!props.disabled,
            }}
            onClick={() => {
              const subtract = () => {
                const qtyInput = document.getElementById(
                  "qtyInput"
                ) as HTMLInputElement;
                if (qtyInput) {
                  const newVal = parseInt(qtyInput.value) - 1;
                  qtyInput.value = newVal.toString();
                  setQty(newVal);
                }
              };
              orderData.selected.quantity > 1 ? subtract() : null;
            }}
          >
            <Icon path={minus} class="w-4" />
          </button>
          <TextFieldInput
            id="qtyInput"
            type="number"
            class="rounded-none text-center"
            disabled={props.disabled}
            onChange={setQty}
            defaultValue={orderData.selected.quantity.toString()}
          />
          <button
            aria-label="increment quantity by 1"
            class="p-2 border border-l-0 border-gray-300"
            classList={{
              hidden: !!props.disabled,
            }}
            onClick={() => {
              const qtyInput = document.getElementById(
                "qtyInput"
              ) as HTMLInputElement;
              if (qtyInput) {
                const newVal = parseInt(qtyInput.value) + 1;
                qtyInput.value = newVal.toString();
                setQty(newVal);
              }
            }}
          >
            <Icon path={plus} class="w-4" />
          </button>
        </div>
      </div>
    </Show>
  );
};
