// ! This is only used once, and messy. Was likely used before we had bulk inventory endpoint.
// ! refactor StackInventorySelector when able

import type { ProductInfoResponse } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";


export const retrieveProductInfo = async (
  sku: string
): Promise<ProductInfoResponse> => {

  // await simulateApiError({path: `/products/${sku}/info`})

  const data = await fetchAPI2<ProductInfoResponse>(`/products/${sku}/info`);


  return data;
};
