import { Component, createEffect, Show } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { Accordion } from "~/components/ui";

export const Sizing: Component = (props: {
  insideText?: string;
  outsideText?: string;
  class?: string;
}) => {
  const { orderData, setOrderData } = useItemOrderContext();

  createEffect(() => {
    if (!orderData.editProduct) {
      if (orderData.isStretcher) {
        setOrderData("selected", "sizing", "O");
      } else {
        setOrderData("selected", "sizing", "I");
      }
    }
  });

  return (
    <>
      <div
        class="grid grid-cols-2 border"
        classList={{ [`${props.class}`]: !!props.class }}
      >
        <button
          class="p-3 font-bold text-sm transition-colors duration-500"
          classList={{
            "bg-black text-white": orderData.selected.sizing == "I",
          }}
          onClick={() => {
            setOrderData("selected", "sizing", "I");
          }}
        >
          {props.insideText ?? "Inside"}
        </button>
        <button
          class="p-3 font-bold text-sm transition-colors duration-500"
          classList={{
            "bg-black text-white": orderData.selected.sizing == "O",
          }}
          onClick={() => {
            setOrderData("selected", "sizing", "O");
          }}
        >
          {props.outsideText ?? "Outside"}
        </button>
      </div>

      <Show when={orderData.isFloater && orderData.selected.sizing === "I"}>
        <div class="w-full border bg-orange-50 p-4 mt-4 text-sm text-roma-dark-grey shadow-sm">
          <Accordion
            name="floater-info"
            label={
              <div class="flex items-center gap-2">
                <svg
                  width="1.5rem"
                  height="1.5rem"
                  viewBox="0 0 15 15"
                  class="-rotate-90"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M.5 4a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5H.5Zm.5 6V5h1.075v2.5a.425.425 0 0 0 .85 0V5h1.15v1.5a.425.425 0 0 0 .85 0V5h1.15v1.5a.425.425 0 0 0 .85 0V5h1.15v2.5a.425.425 0 0 0 .85 0V5h1.15v1.5a.425.425 0 0 0 .85 0V5h1.15v1.5a.425.425 0 0 0 .85 0V5H14v5H1Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>Floater frame measurement information</span>
              </div>
            }
            icon="Chevron"
            iconRotation="180"
            childClass="text-xs"
            contentHeight="Large"
          >
            <div class=" flex flex-col gap-1">
              <p>
                When ordering a floater frame with inside dimensions, there are
                some considerations to keep in mind with standard vs stepped
                floater mouldings.
              </p>
              <p class="font-bold underline text-sm mt-2">Standard</p>
              <div class="grid grid-cols-[50px_auto] gap-3 items-center">
                <svg
                  width="50px"
                  height="50px"
                  viewBox="0 0 417 417"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  //@ts-ignore
                  xml:space="preserve"
                  xmlns:serif="http://www.serif.com/"
                  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
                >
                  <path
                    d="M62.5,62.5l0,291.667l291.667,-0l-0,-62.5l-229.167,-0l-0,-229.167l-62.5,-0Z"
                    style="fill:none;stroke:#000;stroke-width:6.25px;"
                  />
                </svg>
                <p>
                  Standard floater frames are typically ordered with{" "}
                  <strong>'Exact' allowance</strong>, with the reveal amount
                  determined by you and included in the provided width and
                  height. For example if your artwork is{" "}
                  <span class="fraction">10" x 10"</span> and you'd like a{" "}
                  <span class="fraction">1/4"</span> reveal, your inside
                  measurements should be{" "}
                  <span class="fraction">10 1/2" x 10 1/2"</span>.
                </p>
              </div>
              <p class="font-bold underline text-sm mt-2">Stepped</p>
              <div class="grid grid-cols-[50px_auto] gap-3 items-center">
                <svg
                  width="50px"
                  height="50px"
                  viewBox="0 0 417 417"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  //@ts-ignore
                  xml:space="preserve"
                  xmlns:serif="http://www.serif.com/"
                  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
                >
                  <path
                    d="M62.5,62.5l0,291.667l291.667,-0l-0,-62.5l-166.667,-0l-0,-62.5l-62.5,-0l-0,-166.667l-62.5,-0Z"
                    style="fill:none;stroke:#000;stroke-width:6.25px;"
                  />
                </svg>
                <p>
                  Stepped floater frames differ in that the 'step' provides a
                  built-in reveal. They are measured and cut from the lower
                  step, and you select your preferred allowance amount.
                </p>
              </div>
            </div>
          </Accordion>
        </div>
      </Show>
    </>
  );
};