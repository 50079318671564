import { Component, onCleanup } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { SelectBox } from "~/components/inputs";
import {
  ORIENTATION,
  type OrientationOption,
  OrientationLabels,
} from "~/services/roma-api/cart/types-productOrdering";
import { Icon } from "solid-heroicons";
import { questionMarkCircle } from "solid-heroicons/outline";
// import { Tooltip } from "~/components/inputs-v2";

// TODO - Tooltip

export const orientationOptions = Object.values(ORIENTATION).map((value) => ({
  value: value as OrientationOption,
  label: OrientationLabels[value],
}));

export const Orientation: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();

  // Remove orientation value from store on cleanup
  onCleanup(() => {
    setOrderData("selected", "orientation", undefined);
  });

  return (
    <SelectBox
      label={
        <div class="flex items-center">
          <p>Orientation</p>
          {/* <Tooltip
            class="max-w-xs"
            content="Stretcher mouldings can be joined flat, in 'Standard' orientation, or flipped 90° for a 'Gallery Wrap'."
          >
            <Icon path={questionMarkCircle} class="w-4 h-4 ml-2" />
          </Tooltip> */}
        </div>
      }
      triggerClass="rounded-sm"
      options={[
        { label: "Standard", value: "standard" },
        { label: "Gallery Wrap", value: "gallery" },
      ]}
      defaultValue={orderData.selected.orientation}
      onChange={(option) => {
        setOrderData(
          "selected",
          "orientation",
          option.value as OrientationOption
        );
      }}
    />
  );
};
