import { Show, createMemo, createEffect, createSignal } from "solid-js";
import { createStore, produce } from "solid-js/store";
import { useItemOrderContext } from "~/utils/contexts";
import fractionString from "~/utils/fraction";
import { ProductImage } from "~/components/product";

const imgPath = import.meta.env.VITE_ROMA_CDN;

export const CalculatedDimensions = () => {
  const { orderData, setOrderData } = useItemOrderContext();
  const inside = createMemo(() => {
    if (orderData.selected.sizing == "I") return true;
    return false;
  });
  const [showAllDimensions, setShowAllDimensions] = createSignal(false);

  // * Note: Typescript does not like that the values within the store could be undefined.
  // * ! is used to bypass the errors - there are multiple checks in surrounding components
  // * that ensure these values would be populated. Should have some internal safety checks
  // * within this component, but ¯\_(ツ)_/¯
  // * ( @ts-expect-error so this shows up in search )
  const [values, _] = createStore({
    get outsideWidth() {
      if (inside()) {
        return (
          orderData.selected.width! +
          (orderData.selected.stacks.insideDetails.fillet
            ? 0
            : orderData.selected.stacks.insideDetails.mouldingWidth! * 2) +
          (orderData.selected.stackCount == 3
            ? orderData.selected.stacks.middleDetails.mouldingWidth! * 2
            : 0) +
          orderData.selected.stacks.outsideDetails.mouldingWidth! * 2
        );
      }
      return orderData.selected.width;
    },
    get outsideHeight() {
      if (inside()) {
        return (
          orderData.selected.height! +
          (orderData.selected.stacks.insideDetails.fillet
            ? 0
            : orderData.selected.stacks.insideDetails.mouldingWidth! * 2) +
          (orderData.selected.stackCount == 3
            ? orderData.selected.stacks.middleDetails.mouldingWidth! * 2
            : 0) +
          orderData.selected.stacks.outsideDetails.mouldingWidth! * 2
        );
      }
      return orderData.selected.height;
    },
    get outsideWidthInnerEdge() {
      return (
        this.outsideWidth! -
        orderData.selected.stacks.outsideDetails.mouldingWidth! * 2
      );
    },
    get outsideHeightInnerEdge() {
      return (
        this.outsideHeight! -
        orderData.selected.stacks.outsideDetails.mouldingWidth! * 2
      );
    },
    get middleWidth() {
      if (inside()) {
        return (
          orderData.selected.width! +
          (orderData.selected.stacks.insideDetails.fillet
            ? 0
            : orderData.selected.stacks.insideDetails.mouldingWidth! * 2) +
          orderData.selected.stacks.middleDetails.mouldingWidth! * 2
        );
      }
      return (
        orderData.selected.width! -
        orderData.selected.stacks.outsideDetails.mouldingWidth! * 2
      );
    },
    get middleHeight() {
      if (inside()) {
        return (
          orderData.selected.height! +
          (orderData.selected.stacks.insideDetails.fillet
            ? 0
            : orderData.selected.stacks.insideDetails.mouldingWidth! * 2) +
          orderData.selected.stacks.middleDetails.mouldingWidth! * 2
        );
      }
      return (
        orderData.selected.height! -
        orderData.selected.stacks.outsideDetails.mouldingWidth! * 2
      );
    },
    get middleWidthInnerEdge() {
      return (
        this.middleWidth -
        orderData.selected.stacks.middleDetails.mouldingWidth! * 2
      );
    },
    get middleHeightInnerEdge() {
      return (
        this.middleHeight -
        orderData.selected.stacks.middleDetails.mouldingWidth! * 2
      );
    },
    get insideWidth() {
      if (inside()) {
        return (
          orderData.selected.width! +
          orderData.selected.stacks.insideDetails.mouldingWidth! * 2
        );
      }
      return (
        orderData.selected.width! -
        orderData.selected.stacks.outsideDetails.mouldingWidth! * 2 -
        (orderData.selected.stackCount == 3
          ? orderData.selected.stacks.middleDetails.mouldingWidth! * 2
          : 0)
      );
    },
    get insideHeight() {
      if (inside()) {
        return (
          orderData.selected.height! +
          orderData.selected.stacks.insideDetails.mouldingWidth! * 2
        );
      }
      return (
        orderData.selected.height! -
        orderData.selected.stacks.outsideDetails.mouldingWidth! * 2 -
        (orderData.selected.stackCount == 3
          ? orderData.selected.stacks.middleDetails.mouldingWidth! * 2
          : 0)
      );
    },
    get insideWidthInnerEdge() {
      return (
        this.insideWidth -
        orderData.selected.stacks.insideDetails.mouldingWidth! * 2
      );
    },
    get insideHeightInnerEdge() {
      return (
        this.insideHeight -
        orderData.selected.stacks.insideDetails.mouldingWidth! * 2
      );
    },
  });

  createEffect(() => {
    if (orderData.selected.sizing == "O") {
      setOrderData(
        produce((data) => {
          data.selected.stacks.outsideDetails.width = values.outsideWidth;
          data.selected.stacks.outsideDetails.height = values.outsideHeight;
          data.selected.stacks.middleDetails.width = values.middleWidth;
          data.selected.stacks.middleDetails.height = values.middleHeight;
          data.selected.stacks.insideDetails.width = values.insideWidth;
          data.selected.stacks.insideDetails.height = values.insideHeight;
        }),
      );
    } else if (orderData.selected.sizing == "I") {
      setOrderData(
        produce((data) => {
          data.selected.stacks.outsideDetails.width =
            values.outsideWidthInnerEdge;
          data.selected.stacks.outsideDetails.height =
            values.outsideHeightInnerEdge;
          data.selected.stacks.middleDetails.width =
            values.middleWidthInnerEdge;
          data.selected.stacks.middleDetails.height =
            values.middleHeightInnerEdge;
          data.selected.stacks.insideDetails.width =
            values.insideWidthInnerEdge;
          data.selected.stacks.insideDetails.height =
            values.insideHeightInnerEdge;
        }),
      );
    }
  });

  return (
    <div>
      <div class="flex justify-between">
        <p class="mb-2 text-sm font-bold text-roma-dark-grey">
          Calculated Dimensions
        </p>
        <button
          aria-label={
            showAllDimensions()
              ? "Hide supplementary measurements"
              : "Show supplementary measurements"
          }
          class="text-sm font-bold text-roma-blue"
          onClick={() => {
            setShowAllDimensions(!showAllDimensions());
          }}
        >
          {showAllDimensions() ? "Hide" : "View All"}
        </button>
      </div>
      <div class="bg-faint-blue border text-sm child:py-2 divide-y">
        <div class="grid grid-cols-3 px-3 items-center">
          <div class="flex items-center gap-3">
            <div class="bg-roma-grey w-10 h-10 p-1 border flex items-center">
              <ProductImage
                src={`${imgPath}/mouldings/${orderData.selected.stacks.outsideDetails.sku}-1.png?v=2`}
                width={32}
                height={32}
                quality={100}
                alt=""
              />
            </div>
            <div class="flex flex-col">
              <p>Outside Frame</p>
              <p>{orderData.selected.stacks.outsideDetails.sku}</p>
            </div>
          </div>
          <div class="text-center">
            <p>Outside Edge</p>
            <Show when={showAllDimensions()}>
              <p>Inside Edge</p>
            </Show>
          </div>
          <div class="">
            <p class="flex items-center gap-1 fraction justify-end">
              {fractionString(values.outsideWidth)}
              <span class="text-xs">&#10005;</span>
              {fractionString(values.outsideHeight)}
            </p>
            <Show when={showAllDimensions()}>
              <p class="flex items-center gap-1 fraction justify-end">
                {fractionString(values.outsideWidthInnerEdge)}
                <span class="text-xs">&#10005;</span>
                {fractionString(values.outsideHeightInnerEdge)}
              </p>
            </Show>
          </div>
        </div>
        <Show
          when={
            orderData.selected.stacks.middleDetails.sku !== undefined &&
            orderData.selected.stackCount == 3
          }
        >
          <div class="grid grid-cols-3 px-3 items-center">
            <div class="flex items-center gap-3">
              <div class="bg-roma-grey w-10 h-10 p-1 border flex items-center">
                <ProductImage
                  src={`${imgPath}/mouldings/${orderData.selected.stacks.middleDetails.sku}-1.png`}
                  width={32}
                  height={32}
                  quality={100}
                  alt=""
                />
              </div>
              <div class="flex flex-col">
                <p>Middle Frame</p>
                <p>{orderData.selected.stacks.middleDetails.sku}</p>
              </div>
            </div>
            <div class="text-center">
              <Show
                when={showAllDimensions()}
                fallback={<p class="fraction">&mdash;</p>}
              >
                <p>Outside Edge</p>
                <p>Inside Edge</p>
              </Show>
            </div>
            <Show
              when={showAllDimensions()}
              fallback={<p class="fraction text-right">&mdash;</p>}
            >
              <div>
                <p class="flex items-center gap-1 fraction justify-end">
                  {fractionString(values.middleWidth)}
                  <span class="text-xs">&#10005;</span>
                  {fractionString(values.middleHeight)}
                </p>
                <p class="flex items-center gap-1 fraction justify-end">
                  {fractionString(values.middleWidthInnerEdge)}
                  <span class="text-xs">&#10005;</span>
                  {fractionString(values.middleHeightInnerEdge)}
                </p>
              </div>
            </Show>
          </div>
        </Show>
        <div class="grid grid-cols-3 px-3 items-center">
          <div class="flex items-center gap-3">
            <div class="bg-roma-grey w-10 h-10 p-1 border flex items-center">
              <ProductImage
                src={`${imgPath}/mouldings/${orderData.selected.stacks.insideDetails.sku}-1.png`}
                width={32}
                height={32}
                quality={100}
                alt=""
              />
            </div>
            <div class="flex flex-col">
              <p>Inside Frame</p>
              <p>{orderData.selected.stacks.insideDetails.sku}</p>
            </div>
          </div>
          <div class="text-center">
            <Show when={showAllDimensions()}>
              <p>Outside Edge</p>
            </Show>
            <p>Inside Edge</p>
          </div>
          <div>
            <Show when={showAllDimensions()}>
              <p class="flex items-center gap-1 fraction justify-end">
                {fractionString(values.insideWidth)}
                <span class="text-xs">&#10005;</span>
                {fractionString(values.insideHeight)}
              </p>
            </Show>
            <p class="flex items-center gap-1 fraction justify-end">
              {fractionString(values.insideWidthInnerEdge)}
              <span class="text-xs">&#10005;</span>
              {fractionString(values.insideHeightInnerEdge)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
