import {
  Component,
  createSignal,
  Show,
  Accessor,
  createEffect,
  useContext,
  For,
} from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { Motion, Presence } from "solid-motionone";

type AnyRecord = Record<any, any>;

export const StoreValuesHelper: Component<{
  opened: Accessor<boolean>;
  close: VoidFunction;
}> = (props) => {
  const { orderData, searchValue } = useItemOrderContext();
  // const setOverflow = (value = "hidden") => {
  //   if (window.document.body.style.overflow != value) {
  //     window.document.body.style.overflow = value;
  //   }
  // };
  // createEffect(() => setOverflow(props.opened() ? "hidden" : "auto"));
  return (
    <Presence exitBeforeEnter>
      <Show when={props.opened()}>
        <Motion.div
          initial={{ x: "100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%", transition: { duration: 0.5 } }}
          transition={{ duration: 0.4, easing: "ease-in-out" }}
          class="z-[500] fixed inset-y-0 right-2 flex flex-col max-w-full pl-10 sm:pl-16 bg-white overflow-y-scroll"
        >
          <button onClick={props.close}> CLOSE </button>
          <div class="border border-orange-500 text-sm text-orange-500 p-2 grid grid-cols-1 mt-4 gap-4">
            <div>
              <p class="text-lg">SearchValue</p>
              {searchValue()}
            </div>

            <div>
              <p class="text-lg">Stretcher?</p>
              {orderData.isStretcher ? "TRUE" : "FALSE"}
            </div>
            <div>
              <p class="text-lg">Floater?</p>
              {orderData.isFloater ? "TRUE" : "FALSE"}
            </div>

            {/* <div>
              <p class="text-lg">showBackorderBox?</p>
              {orderData.selected.showBackorderBox ? "TRUE" : "FALSE"}
            </div> */}
            {/* <div>
              <p class="text-lg">stacked</p>
              {orderData.selected.stacked ? "TRUE" : "FALSE"}
            </div> */}
            {/* <div>
              <p class="text-lg">backorderConsent</p>
              {orderData.selected.backorderConsent ? "TRUE" : "FALSE"}
            </div> */}
            {/* <div>
              <p class="text-lg">freightChargeConsent</p>
              {orderData.selected.freightChargeConsent ? "TRUE" : "FALSE"}
            </div> */}

            {/* <div>
              <p class="font-bold text-lg">
                Store ('selected.stacks.outsideDetails')
              </p>
              <For each={Object.keys(orderData.selected.stacks.outsideDetails)}>
                {(item) => (
                  <p>
                    {item} : {orderData.selected.stacks.outsideDetails[item]}
                  </p>
                )}
              </For>
            </div> */}

            {/* <div>
              <p class="font-bold text-lg">
                Store ('selected.stacks?.middleDetails')
              </p>
              <For each={Object.keys(orderData.selected.stacks?.middleDetails)}>
                {(item) => (
                  <p>
                    {item} : {orderData.selected.stacks?.middleDetails[item]}
                  </p>
                )}
              </For>
            </div> */}

            <div>
              <p class="font-bold text-lg">
                Store ('selected.stacks.insideDetails')
              </p>
              <For each={Object.keys(orderData.selected.stacks.insideDetails)}>
                {(item) => (
                  <p>
                    {item} :{" "}
                    {JSON.stringify(
                      (orderData.selected.stacks.insideDetails as AnyRecord)[
                        item
                      ]
                    )}
                  </p>
                )}
              </For>
            </div>

            <div>
              <p class="font-bold text-lg">
                Store ('selected.stacks.outsideDetails')
              </p>
              <For each={Object.keys(orderData.selected.stacks.outsideDetails)}>
                {(item) => (
                  <p>
                    {item} :{" "}
                    {JSON.stringify(
                      (orderData.selected.stacks.outsideDetails as AnyRecord)[
                        item
                      ]
                    )}
                  </p>
                )}
              </For>
            </div>

            <div>
              <p class="font-bold text-lg">Store (top level)</p>
              <For each={Object.keys(orderData)}>
                {(item) => (
                  <p>
                    {item} : {(orderData as AnyRecord)[item]} (
                    {typeof (orderData as AnyRecord)[item]})
                  </p>
                )}
              </For>
            </div>

            <div>
              <p class="font-bold text-lg">Store ('selected')</p>
              <For each={Object.keys(orderData.selected)}>
                {(item) => (
                  <p>
                    {item} : {(orderData.selected as AnyRecord)[item]} (
                    {typeof (orderData.selected as AnyRecord)[item]})
                  </p>
                )}
              </For>
            </div>

            <div>
              <p class="font-bold text-lg">Store ('selected.stacks')</p>
              <For each={Object.keys(orderData.selected.stacks)}>
                {(item) => (
                  <p>
                    {item} : {(orderData.selected.stacks as AnyRecord)[item]} (
                    {typeof (orderData.selected.stacks as AnyRecord)[item]})
                  </p>
                )}
              </For>
            </div>

            {/* <div class="border">
              <p class="font-bold text-lg">AvailableAs</p>
                <For each={Object.keys(orderData.availableAs)}>
                  {(item) => <p>{item}</p>}
                </For>
              </div> */}
          </div>
        </Motion.div>
      </Show>
    </Presence>
  );
};
