import { Component, useContext, createRenderEffect } from "solid-js";
import { SelectBox } from "~/components/inputs";
import { useItemOrderContext } from "~/utils/contexts";
import {
  ALLOWANCE,
  AllowanceOption,
  AllowanceLabels,
} from "~/services/roma-api/cart/types-productOrdering";

// export const allowanceOptions = [
//   { value: "1/8", label: '1/8" Standard Allowance (Default)' },
//   { value: "1/16", label: '1/16" Allowance' },
//   { value: "exact", label: "Exact" },
//   { value: "3/16", label: '3/16" Allowance' },
//   { value: "sightSize", label: "Sight Size" },
// ];

export const allowanceOptions = Object.values(ALLOWANCE).map((value) => ({
  value: value as AllowanceOption,
  label: AllowanceLabels[value],
}));

// Used for displaying on checkout page
export const allowanceDisplay = (value: AllowanceOption) => {
  if (value == "exact" || value == "sightSize") {
    if (value == "exact") {
      return "Exact";
    } else if (value == "sightSize") {
      return "Sight Size";
    }
  } else {
    return <span class="fraction font-normal">{value}"</span>;
  }
};

export const Allowance: Component = () => {
  const { orderData, setOrderData } = useItemOrderContext();

  createRenderEffect(() => {
    if (!orderData.editProduct) {
      if (orderData.isStretcher || orderData.isFloater) {
        setOrderData("selected", "allowance", "exact");
      } else {
        setOrderData("selected", "allowance", "1/8");
      }
    }
  });

  return (
    <>
      <SelectBox
        options={allowanceOptions}
        label="Allowance"
        placeholder="Select allowance"
        defaultValue={orderData.selected.allowance}
        onChange={(option) =>
          setOrderData("selected", "allowance", option.value as AllowanceOption)
        }
        triggerClass="!rounded-sm"
        disabled={orderData.selected.sizing === "O"}
      />
    </>
  );
};
