import { Component } from "solid-js";
import { Motion } from "solid-motionone";

export const ProductPlaceholder:Component = () => {
  return (
    <div class="flex  flex-row justify-between items-start p-4 bg-white border">
      <div class="flex">
        <Motion.span
          class="inline-block flex-shrink-0 bg-roma-medium-grey/25 w-20 h-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 1.1,
            easing: "ease-in-out",
          }}
        />
        <div class="ml-4 flex flex-col justify-between">
          <div class="flex flex-col gap-2">
            <Motion.div
              class="bg-roma-medium-grey/25 w-16 h-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.5,
                easing: "ease-in-out",
              }}
            />
            <Motion.div
              class="bg-roma-medium-grey/25 w-24 h-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.5,
                easing: "ease-in-out",
                delay: 0.2,
              }}
            />
            <Motion.div
              class="bg-roma-medium-grey/25 w-24 h-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.5,
                easing: "ease-in-out",
                delay: 0.4,
              }}
            />
          </div>
          <Motion.div
            class="bg-roma-medium-grey/25 w-16 h-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.5,
              easing: "ease-in-out",
              delay: 0.6,
            }}
          />
        </div>
      </div>
    </div>
  );
};