import {
  Component,
  onMount,
  onCleanup,
  createEffect,
  createSignal,
  on,
} from "solid-js";
import { produce } from "solid-js/store";
import { useItemOrderContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { check } from "solid-heroicons/outline";

export const CancelBackOrderAcknowledge: Component = () => {
  const { setOrderData } = useItemOrderContext();
  const [choice, setChoice] = createSignal();
  onMount(() => {
    setOrderData(
      produce((store) => {
        store.selected.backorderConsent = undefined;
        store.selected.cancelBackorderConsent = undefined;
      })
    );
  });
  onCleanup(() => {
    setOrderData(
      produce((store) => {
        store.selected.backorderConsent = undefined;
        store.selected.cancelBackorderConsent = undefined;
      })
    );
  });

  createEffect(
    on(
      () => [choice()],
      ([choice]) => {
        if (choice === "Cancel") {
          setOrderData(
            produce((store) => {
              store.selected.backorderConsent = undefined;
              store.selected.cancelBackorderConsent = true;
            })
          );
        } else if (choice === "Backorder") {
          setOrderData(
            produce((store) => {
              store.selected.backorderConsent = true;
              store.selected.cancelBackorderConsent = undefined;
            })
          );
        }
      }
    )
  );

  return (
    <div class="border bg-faint-blue p-5 flex flex-col gap-3 text-sm text-roma-dark-grey">
      <p>
        This item has low stock but we'll try our best to fulfill it. If we are
        unable to, please choose an option:
      </p>

      <div class="flex items-center">
        <button
          aria-label="Place item on backorder"
          class=" w-5 aspect-square shrink-0 flex justify-center items-center self-center"
          classList={{
            "bg-white border": choice() !== "Backorder",
            "bg-roma-blue": choice() === "Backorder",
          }}
          onClick={() => {
            setChoice("Backorder");
          }}
        >
          <Icon path={check} class="text-white w-4" stroke-width={3} />
        </button>
        <p class="ml-4">Place item on backorder</p>
      </div>

      <div class="flex items-center">
        <button
          aria-label="Cancel item, do not place on backorder"
          class=" w-5 aspect-square shrink-0 flex justify-center items-center self-center"
          classList={{
            "bg-white border": choice() !== "Cancel",
            "bg-roma-blue": choice() === "Cancel",
          }}
          onClick={() => {
            setChoice("Cancel");
          }}
        >
          <Icon path={check} class="text-white w-4" stroke-width={3} />
        </button>
        <p class="ml-4">Cancel item, do not place on backorder</p>
      </div>
    </div>
  );
};
