import { Component, createMemo, Show } from "solid-js";
import fractionString from "~/utils/fraction";
import { PHOTOFRAME, GALLERYFRAME, MIRROR } from "~/utils/products";
import { ProductImage } from "~/components/product";

export const ProductThumbnail: Component<{
  thumbnail?: string;
  sku: string | number;
  width: number;
  collectionName: string;
  colour: string;
  category?: string;
  containerClass?: string;
  disableHover?: boolean;
  onClick?: () => void;
}> = (props) => {
  const imagepath = import.meta.env.VITE_ROMA_CDN;
  const productType = createMemo(() => {
    switch (props.category) {
      case PHOTOFRAME:
        return "photo-frames";
      case GALLERYFRAME:
        return "gallery-frames";
      case MIRROR:
        return "mouldings";
      default:
        return "mouldings";
    }
  });

  return (
    <div
      class="flex  flex-row justify-between items-start p-4 bg-white "
      classList={{
        "group transition-colors hover:bg-roma-grey cursor-pointer":
          !props.disableHover,
        [`${props.containerClass}`]: !!props.containerClass,
      }}
      onClick={props.onClick}
    >
      <div class="flex">
        <span
          class="flex-shrink-0 bg-roma-grey h-20 w-20 flex items-center"
          classList={{
            "group-hover:bg-white": !props.disableHover,
            "p-2": !props.category || props.category == PHOTOFRAME,
            "p-0": props.category == GALLERYFRAME,
          }}
        >
          <Show when={props.sku}>
            <ProductImage
              class="w-full"
              src={`${imagepath}/${productType()}/${props.sku}-1.png`}
              width={100}
              height={100}
              quality={95}
              alt=""
            />
          </Show>
        </span>
        <div class="ml-4">
          <p class="text-base font-bold text-gray-900">{props.sku}</p>
          <p class="text-sm font-light text-roma-dark-grey">
            <span class="fraction mr-1">{fractionString(props.width)}</span>
            {props.collectionName}
          </p>
          <p class="text-sm font-light text-roma-dark-grey">{props.colour}</p>
        </div>
      </div>
    </div>
  );
};
