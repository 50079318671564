import { PT } from "~/utils/products";

// ********** ENUMS *********** //

export enum WEDGE {
  NONE = "none",
  SINGLE = "single",
  DOUBLE = "double",
}
export enum ALLOWANCE {
  STANDARD = "1/8",
  SIXTEENTH = "1/16",
  EXACT = "exact",
  THREESIXTEENTH = "3/16",
  SIGHTSIZE = "sightSize",
}
// ? 'OTHER' may cause issue - currently when 'other' is selected in UI, we send
// ? a user-inputted string inside the 'Cutting' value. We should alter this so that 'other'
// ? is sent, with an *additional property like CuttingOther etc...
export enum CUTTING {
  FIVEBALANCE = "fiveAndBalance",
  SIXBALANCE = "sixAndBalance",
  SEVENBALANCE = "sevenAndBalance",
  EIGHTBALANCE = "eightAndBalance",
  HALF = "half",
  FULL = "full",
  OTHER = "other",
}

export enum ORIENTATION {
  STANDARD = "standard",
  GALLERY = "gallery",
}

export enum BEVEL {
  BEVELLED = "beveled",
  NONE = "noBevel",
}

// Type Aliases..
export type ProductTypeOption = `${PT}`; //
export type WedgeOption = `${WEDGE}`;
export type AllowanceOption = `${ALLOWANCE}`;
export type OrientationOption = `${ORIENTATION}`;
export type BevelOption = `${BEVEL}`;
export type CuttingOption = `${CUTTING}`;
export type StackOperation = `${PT.CHOP}` | `${PT.JOIN}` | `${PT.MIRROR}`;

// ! IMPORTANT
// TODO! - add in additional, optional propertie like Backorder, CancelBackOrder, FreightAccepted, Oversized, etc...

export interface BaseProduct {
  Type: PT | ProductTypeOption;
  Quantity: number;
  Plant: string;
  SubItems: [SubItem];
  Tag?: string;
  BackOrder?: boolean; // ?
  CancelBackOrder?: boolean; //?
  FreightAccepted?: boolean; //?
  Oversized?: boolean; //?
}

export interface SubItem {
  SKU: string;
  Moulding: string;
  Length?: number;
  LengthFraction?: string;
  Width?: number;
  WidthFraction?: string;
  Layer?: "I" | "O" | "2";
  Wedge?: WedgeOption;
  Orientation?: OrientationOption;
}

type SubItemWith<K extends keyof SubItem> = SubItem &
  Required<Pick<SubItem, K>>;

export interface SubItemWithWedge extends SubItem {
  Wedge?: WedgeOption;
}

type SubItemLWL = SubItemWith<"Length" | "Width" | "Layer">;

export interface FrameSize {
  Width: number;
  Length: number;
}

// ******* PRODUCT ORDER TYPES ********* //

export interface LengthProduct extends BaseProduct {
  Type: PT.LENGTH;
  Cutting: CuttingOption;
  // ? add an additional 'CuttingOther', when Cutting = 'other' ??
}
export interface ChopProduct extends BaseProduct {
  Type: PT.CHOP;
  Allowance: AllowanceOption | undefined;
  SubItems: [SubItemLWL];
}
export interface JoinProduct extends BaseProduct {
  Type: PT.JOIN;
  Allowance: AllowanceOption | undefined;
  SubItems: [SubItemLWL];
}
// export interface BoxFields {
//   FreightAccepted: boolean;
//   Oversized: boolean;
// }
// export interface BoxProduct
//   extends Omit<BaseProduct, keyof BoxFields>,
//     BoxFields {
//   Type: PT.BOX;
// }
export interface BoxProduct extends BaseProduct {
  Type: PT.BOX
}

export interface RailProduct extends BaseProduct {
  Type: PT.RAIL;
  Allowance: AllowanceOption;
  SubItems: [SubItemWith<"Length" | "Layer">];
}

export interface PhotoFrameProduct extends BaseProduct {
  Type: PT.PHOTOFRAME;
  FrameSize: FrameSize;
}

export interface GalleryFrameProduct extends BaseProduct {
  Type: PT.GALLERYFRAME;
  FrameSize: FrameSize;
}

export interface MirrorProduct extends BaseProduct {
  Type: PT.MIRROR;
  SubItems: [SubItemLWL];
  Bevel: BevelOption;
}

export interface ContractProduct extends BaseProduct {
  Type: PT.CONTRACT;
  Oversized: true;
  FreightAccepted: true;
}

export interface CornerSampleProduct extends BaseProduct {
  Type: PT.CORNERSAMPLE;
}

export interface StackProduct extends Omit<BaseProduct, "SubItems"> {
  Type: PT.STACK;
  Operation: StackOperation;
  Allowance: AllowanceOption;
  FrameSize: {
    Sizing: string;
    Width: number;
    Length: number;
    WidthFraction: string;
    LengthFraction: string;
    Layers: {
      O: string;
      M2?: string;
      I: string;
    };
  };
  SubItems: [SubItemLWL, SubItemLWL] | [SubItemLWL, SubItemLWL, SubItemLWL];
}

export interface GenericProduct extends BaseProduct {
  Type: PT.PRODUCT;
  AssetID?: string;
  AssetBleed?: string;
}

// Any Product Type
export type OrderProductTypes =
  | LengthProduct
  | ChopProduct
  | JoinProduct
  | BoxProduct
  | RailProduct
  | PhotoFrameProduct
  | GalleryFrameProduct
  | CornerSampleProduct
  | MirrorProduct
  | StackProduct
  | ContractProduct
  | GenericProduct;

// LABELS
// ?Should this be here, or located with PT enum elsewhere?
export const PTLabels: Record<ProductTypeOption, string> = {
  [PT.MOULDING]: "Moulding",
  [PT.LENGTH]: "Length",
  [PT.JOIN]: "Join",
  [PT.CHOP]: "Chop",
  [PT.RAIL]: "Rail",
  [PT.PHOTOFRAME]: "Photo Frame",
  [PT.GALLERYFRAME]: "Gallery Frame",
  [PT.MIRROR]: "Mirror",
  [PT.BOX]: "Box",
  [PT.CONTRACT]: "Contract",
  [PT.STACK]: "Stack",
  [PT.CORNERSAMPLE]: "Corner Sample",
  [PT.PRODUCT] : "Product"
};

// ? Should WEDGE.NONE be added here?
export const WedgeLabels: Record<WedgeOption, string> = {
  [WEDGE.NONE]: "Not Required",
  [WEDGE.SINGLE]: "Single Wedge",
  [WEDGE.DOUBLE]: "Double Wedge",
};

export const CuttingLabels: Record<CuttingOption, string> = {
  [CUTTING.FIVEBALANCE]: "5' and balance",
  [CUTTING.SIXBALANCE]: "6' and balance",
  [CUTTING.SEVENBALANCE]: "7' and balance",
  [CUTTING.EIGHTBALANCE]: "8' and balance",
  [CUTTING.HALF]: "Cut in half",
  [CUTTING.FULL]: "None (Full Length)",
  [CUTTING.OTHER]: "Other",
};

export const AllowanceLabels: Record<AllowanceOption, string> = {
  [ALLOWANCE.STANDARD]: '1/8" Standard Allowance',
  [ALLOWANCE.SIXTEENTH]: '1/16" Allowance',
  [ALLOWANCE.EXACT]: "Exact",
  [ALLOWANCE.THREESIXTEENTH]: '3/16" Allowance',
  [ALLOWANCE.SIGHTSIZE]: "Sight Size",
};

export const AllowanceLabelsAbbr : Record<AllowanceOption, string> = {
  [ALLOWANCE.STANDARD]: '1/8"',
  [ALLOWANCE.SIXTEENTH]: '1/16"',
  [ALLOWANCE.EXACT]: "Exact",
  [ALLOWANCE.THREESIXTEENTH]: '3/16"',
  [ALLOWANCE.SIGHTSIZE]: "Sight Size",
};

export const OrientationLabels: Record<OrientationOption, string> = {
  [ORIENTATION.STANDARD]: "Standard",
  [ORIENTATION.GALLERY]: "Gallery Wrap",
};

export const BevelLabels: Record<BevelOption, string> = {
  [BEVEL.BEVELLED]: "Beveled",
  [BEVEL.NONE]: "Non-Beveled",
};
